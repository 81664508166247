import React, { useState, useEffect } from "react";
import styled from "styled-components";
import axios from "axios";
import { useQuery } from "react-query";
import { URL } from "../../../../utils/url";
import { useParams, useNavigate } from "react-router-dom";
import { formatDate } from "../../../../utils/helper";
import { LoadingSpinner } from "../../../../utils/loader";
import { Button3 } from "../../../dashboard/components/button/Button2";
import NoEventFound from "../../../website/components/NoEventFound";
import NotActive from "../../../website/components/NotActive";
import EventEnded from "../../../website/components/EventEnded";

export const WelcomeScreen = ({ title }) => {
  const [welcomeScreenData, setWelcomeScreenData] = useState(null);
  const { token } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (welcomeScreenData) {
      const eventDate = new Date(welcomeScreenData.event_date);
      const eventYear = eventDate.getFullYear();
      localStorage.setItem("eventYear", eventYear.toString());
      localStorage.setItem("eventTag", welcomeScreenData.event_tag);
      localStorage.setItem("eventId", welcomeScreenData.event_uuid);
      localStorage.setItem("trivia", welcomeScreenData.trivia_active);
    }
  }, [welcomeScreenData]);

  const handleOnSubmit = () => {
    document.querySelector(".welcome-screen").classList.add("transition");
    setTimeout(() => navigate(`/${token}/food-menu`), 1000);
  };

  const { isLoading: isWelcomeLoading } = useQuery(
    ["welcome", token],
    () => axios.get(`${URL}guest-event/${token}/`),
    {
      onSuccess: (response) => setWelcomeScreenData(response.data.data),
    }
  );

  if (isWelcomeLoading) {
    return (
      <StyledWelcome>
        <div className="loader">
          <LoadingSpinner />
        </div>
      </StyledWelcome>
    );
  }

  if (!welcomeScreenData) {
    return (
      <StyledWelcome>
        <NoEventFound />
      </StyledWelcome>
    );
  }

  if (welcomeScreenData.active === false) {
    return (
      <StyledWelcome>
        <NotActive />
      </StyledWelcome>
    );
  }

  const eventDate = new Date(welcomeScreenData.event_date);
  const currentDate = new Date();

  // if (currentDate > eventDate) {
  //   return (
  //     <StyledWelcome>
  //       <EventEnded />
  //     </StyledWelcome>
  //   );
  // }

  return (
    <StyledWelcome>
      <div
        className="welcome-screen"
        style={{ backgroundImage: `url(${welcomeScreenData.even_background})` }}
      >
        <div className="rectangle" />
        <div className="content">
          <div className="content-top">
            <div className="welcome-div">
              <p className="welcome mb-4">Welcome to</p>
            </div>
            <div className="tag-div">
              <h1 className="tag">{welcomeScreenData.event_tag.toUpperCase()}</h1>
            </div>
            <div className="date-cup-div mt-4">
              <p className="tag">{formatDate(welcomeScreenData.event_date)}</p>
              <img src="/images/cup.png" className="cup-img" alt="cup-icon" />
            </div>
            <div className="toast-div mt-3">
              <p id="t">{welcomeScreenData.event_toast}</p>
            </div>
          </div>
          <div className="content-bottom">
            <Button3 title="Enter Event" onClick={handleOnSubmit} src="/images/arrow-right.svg" alt="update" />
          </div>
        </div>
        <div className="footer">
          <p>Powered by Wiserve</p>
        </div>
      </div>
    </StyledWelcome>
  );
};

const StyledWelcome = styled.div`
.content-bottom{
  margin-bottom: -90px;
}
  .welcome-screen {
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    opacity: 0;
    animation: fadeIn 5s forwards; 
  }

  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }

  .loader {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 50vh;
  }

  .content {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 98vh;
  }

  .welcome-div p,
  .tag-div h1,
  .date-cup-div p,
  .toast-div #t,
  .footer p {
    color: white;
    text-align: center;
  }

  .welcome-div p {
    font-family: "MatterNormal", sans-serif;
    font-size: 25px;
    font-weight: 500;
  }

  .tag-div h1 {
    font-family: "MatterMedium", sans-serif;
    font-size: 30px;
    font-weight: 500;
    /* letter-spacing: 2px; */
  }

  .date-cup-div {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
  }

  .date-cup-div p {
    font-family: "MatterNormal", sans-serif;
    font-size: 30px;
    font-weight: 400;
    /* letter-spacing: 2px; */
  }

  .cup-img {
    width: 44px;
    height: 35px;
  }

  .toast-div #t {
    font-family: "MatterNormal", sans-serif;
    font-size: 25px;
    font-weight: 400;
    /* letter-spacing: 2px; */
  }

  .footer p {
    font-family: "MatterNormal", sans-serif;
    font-size: 18px;
    font-weight: 300;
    position: relative;
    bottom: 25px;
  }
`;
